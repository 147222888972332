import Cookies from "js-cookie";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import { Loading } from "./../components/loading/Loading";
import { getAccessToken, validateUser } from "./../constants/validateUser";

import DashboardLayout from "./../layouts/dashboard";
import { useInfo } from "./../store/userData";

export const IndexPage = lazy(() => import("./../pages/app"));
export const BlogPage = lazy(() => import("./../pages/blog"));
export const UserPage = lazy(() => import("./../pages/user"));
export const LoginPage = lazy(() => import("./../pages/login"));
export const ProductsPage = lazy(() => import("./../pages/products"));
export const Page404 = lazy(() => import("./../pages/page-not-found"));
export const NotificationsPage = lazy(() => import("./../pages/notifications"));
export const HomePage = lazy(() => import(`./../pages/Home/Home`));
export const JobPosts = lazy(() => import("./../pages/jobPosts"));
export const Feedbacks = lazy(() => import(`./../pages/feedback`));
export const UserReports = lazy(() => import(`./../pages/reports`));

// import { Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function Router() {
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);

  //log in status
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //global state
  const { zemail, zrole, setZemail, setZrole } = useInfo((a) => ({
    zemail: a.zemail,
    zrole: a.zrole,
    setZemail: a.setZemail,
    setZrole: a.setZrole,
  }));

  //first render
  //first render with a cookie for role
  // useEffect(() => {
  //   const roleCook = Cookies.get("rolesCookie");
  //   const cookieSecret = import.meta.env.VITE_ROLECOOKIE;
  //   // console.log("hey",cookieSecret);
  //   if(roleCook === cookieSecret){
  //     setZrole("admin");
  //     setIsLoggedIn(true)
  //   }else{
  //     setZrole("");
  //     setIsLoggedIn(false)
  //   }
  // },[])

  useEffect(() => {
    if (!zrole) {
      //function to get current user data
      const myUser = async () => {
        const userDetails = await validateUser();
        if (userDetails?.data?.data?.role === "admin") {
          setZrole("admin");
          // const cookieSecret = import.meta.env.VITE_ROLECOOKIE;
          // Cookies.set('rolesCookie', cookieSecret, { expires: 7 })
          console.log("zrole set");
        }
      };

      myUser();
    }
  }, []);

  //to remove loading
  useEffect(() => {
    console.log("the zrole has been changed here::", zrole);
    if (zrole) {
      // setLoading(false);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [zrole]);

  const routes = useRoutes([
    {
      element: isLoggedIn ? (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ) : (
        <LoginPage />
      ),
      children: [
        // { element: <IndexPage />, index: true },
        {
          path: "dashboard",
          element: zrole === "admin" ? <IndexPage /> : <Page404 />,
        },

        {
          path: "user",
          element: zrole === "admin" ? <UserPage /> : <Page404 />,
        },
        {
          path: "notification",
          element: zrole === "admin" ? <NotificationsPage /> : <Page404 />,
        },

        { path: "products", element: <ProductsPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "jobs", element: <JobPosts /> },
        { path: "feedback", element: <Feedbacks /> },
        { path: "user-reports", element: <UserReports /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },

    {
      path: "/",
      element: <HomePage />,
    },

    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  // console.log("this is the first value",process.env.REACT_APP_ROLECOOKIE);
  return routes;
}
