import SvgColor from "./../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "user",
    path: "/user",
    icon: icon("ic_user"),
  },
  {
    title: "Notifications",
    path: "/notification",
    icon: icon("ic_user"),
  },
  {
    title: "Job Posts",
    path: "/jobs",
    icon: icon("ic_user"),
  },
  {
    title: "Feedback",
    path: "/feedback",
    icon: icon("ic_user"),
  },
  {
    title: "User Reports",
    path: "/user-reports",
    icon: icon("ic_user"),
  },
];

export default navConfig;
