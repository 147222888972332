//to get accesstoken from cookies and set to Authorization header

import axios from "axios";

import Cookies from "js-cookie";
import { BACKEND_URL, LOCAL_B_URL } from "./urlVariables";

export const getAccessToken = () => {
    const tokens = Cookies.get("chayeootoken");
    const token = tokens ?tokens :"";
    return token;
}

export const setHeadersAuth = () => {
const token = getAccessToken();
    const headers = {
        Authorization:"Bearer " + token,
    }
    return headers;
};

//to validate user

export const validateUser = async() => {
    const token = getAccessToken();

    if(token.length >1){
        let result;
        try {
            const response = await axios.get(
                // LOCAL_B_URL + `/sessions/about-me/info`,
                BACKEND_URL + `/sessions/about-me/info`,
                {
                  headers:setHeadersAuth(),
                }
            )
            result = response;
        } catch (error) {
            result = error;
        }
        return result;
    }
}