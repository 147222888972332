/* eslint-disable arrow-body-style */
import React from "react";
import { RingLoader } from "react-spinners";
import { Container } from "@mui/material";

export const Loading = () => {
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <RingLoader />
      </Container>
    </>
  );
};
