import {create} from "zustand";

//a custom hook called useInfo

export const useInfo = create((set) => ({
    zemail:"",
    zrole:"",

    //function to update email state
    setZemail:(a) => set({zemail:a}),

    //function tto update role state
    setZrole:(b) => set({zrole:b})
}))